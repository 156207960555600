<template>
  <div v-if="user" class="d-flex user-opts">
    <b-dropdown class="opts-dropdown" variant="link" right no-caret>
      <template v-slot:button-content>
        <user-image :user="user">
          <template slot="info">
            <strong>{{ user.username }}</strong>
            <label-tag small>{{ user.profile.name }}</label-tag>
          </template>
        </user-image>
      </template>

      <b-dropdown-text>
        <b-link
          v-if="user.is_superuser || user.profile.permissions.includes('view_settings')"
          :to="{ name: 'settings' }"
        >
          <span>{{ $t('general.settings') }}</span>
        </b-link>
        <b-button v-if="user" block @click="logout()" variant="bordered" size="small">{{ $t('general.closeSession') }}</b-button>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import './UserOpts';
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    UserImage: () => import('@/components/UserImage/UserImage.vue'),
    LabelTag: () => import('@/components/LabelTag/LabelTag.vue'),
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
